'use strict';

angular.module('kljDigitalLibraryApp')
    .service('CaseService', ["common", "appConfig", "$q", function(common, appConfig, $q) {

        this.totalCases = [];
        this.openedTabs = [];
        const FILTER = 'filter';
        this.stopCallingApi = false;
        this.getAll = function(receivedBody, appendRecords) {

            var body = receivedBody;
            
            var url = '/api/cases/caseSearch';
            var that = this;
            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(cases) {
                    
                    if (cases && cases.data && cases.data.cases && cases.data.cases.length > 0) {
                        if(cases.data.totalCount==10){
                             that.stopCallingApi = true;
                        }
                        else if(cases.data.cases.length > 9) {
                            that.stopCallingApi = false;
                        }
                        else if(cases.data.cases.length <= 9){
                            
                             that.stopCallingApi = true;
                        }
                       
                    } else {
                        that.stopCallingApi = true;
                    }

                    if (appendRecords === false || appendRecords === undefined || appendRecords === '') {

                        that.totalCases = [];
                        that.totalCount= cases.data.totalCount;
                        that.totalCases = that.totalCases.concat(cases.data.cases);
                        return {cases:that.totalCases, totalCount: that.totalCount};

                    } else {

                        //that.totalCases.push(cases.data);
                        that.totalCount= cases.data.totalCount;
                        that.totalCases = that.totalCases.concat(cases.data.cases);
                    }

                    return {cases:that.totalCases, totalCount: that.totalCount};
                })
                .catch(function(error) {
                    that.stopCallingApi = true;
                    if (receivedBody.offset == 0) {
                        that.totalCases = [];
                    }
                    // return that.totalCases;
                    return error
                })

        }

        this.getDetails = function(id, type, body) {
            var deferred = $q.defer();


            var url = '/api/cases/getCase/' + id;
            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(cases) {
                return cases.data;
            })
            .catch(function(err){
                err.html=err.data;
                return err;
            })

        }

        this.getTags = function(id, tag) {

            var url = '/api/cases/getCaseTags/' + id;

            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE, {
                    tag: tag,
                    caseCategory: "KLJ"
                }
            )

            .then(function(cases) {
                return cases.data
            })

        };

        this.getOpenedTab = function(body) {


            var url = '/api/users/get/userSavedSearchTabsData/';
            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(openedTabs) {
                return openedTabs.data
            })
        }

        this.openNote = function(body) {
            var url = '/api/cases/caseSearch';
            var that = this;
            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(response) {
                that.stopCallingApi = true;
                return response.data
            })
            .catch(function(err){
                that.stopCallingApi = true;
                console.log(err,"====error==");
            })
        }

        this.getPrintFile = function(body){
            var url= '/api/offlineBuild/printFile';
            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )
            .then(function(response) {
                return response.data
            })
            .catch(function(err){
               
                return err;
            })
        }

        // //Add opened tab info
        // this.addOpenedTabInformation = function(case){


        // }



    }]);